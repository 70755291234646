import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Table, Button, Tag} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import jobRequestJson from '../../../content/official/job-request.json';
import roomRequestJson from '../../../content/official/room-request.json';
import companyRequestJson from '../../../content/official/company-request.json';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

const TYPES = {
  job: {
    title: '求才刊登申請',
    json: jobRequestJson,
  },
  room: {
    title: '辦公室刊登申請',
    json: roomRequestJson,
  },
  company: {
    title: '事務所變更申請',
    json: companyRequestJson,
  },
};

function RequestList(props) {
  const {type} = props.pageContext;

  const [user] = useOutlet('user');
  const [records, setRecords] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [pagination, setPagination] = React.useState({page: 1, pageSize: 10});

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        let resp = await JStorage.fetchDocuments(
          'review_request',
          {
            type,
            user: user.sub,
          },
          ['-created'],
          {
            offset: (pagination.page - 1) * pagination.pageSize,
            limit: pagination.pageSize,
          },
        );

        resp.results = resp.results.map((r) => ({
          ...r,
          ...r.data,
        }));

        if (type === 'company') {
          let cIds = resp.results.map((record) => ({
            $oid: record.company_current,
          }));
          let companies = await JStorage.fetchAllDocuments(
            'Company',
            {
              _id: {$in: cIds},
            },
            null,
            {CONAME: 1},
          );
          resp.results = resp.results.map((record) => {
            let cIdx = companies.findIndex(
              (c) => c.id === record.company_current,
            );
            return {
              ...record,
              CONAME: cIdx > -1 ? companies[cIdx].CONAME : null,
            };
          });
        }

        setRecords(resp.results);
        setTotal(resp.total);

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [pagination.page, pagination.pageSize, type, user]);

  return (
    <Wrapper>
      <div className="container">
        <div className="title-container">
          <h1>{TYPES[type].title}</h1>
          <Button
            type="primary"
            onClick={() => AppActions.navigate(`/${type}-request`)}>
            提出申請
          </Button>
        </div>

        <Table
          dataSource={records}
          pagination={{total, current: pagination.page}}
          columns={[
            {
              title: '申請時間',
              key: 'created',
              dataIndex: 'created',
              render: (_, record) => (
                <span>{new Date(record.created).toLocaleString('sv')}</span>
              ),
            },
            ...TYPES[type].json.columns,
            type === 'company'
              ? {
                  title: '欲變更事務所',
                  key: 'company_data',
                  dataIndex: 'company_data',
                  render: (_, record) => {
                    return record.company_current ? (
                      record.CONAME
                    ) : (
                      <div>
                        <div>{record.company_name}</div>
                        <div>電話：{record.company_tel}</div>
                        <div>傳真：{record.company_fax}</div>
                        <div>{record.company_email}</div>
                        <div>{record.company_address}</div>
                      </div>
                    );
                  },
                }
              : {},
            {
              title: '審核狀態',
              key: 'status',
              dataIndex: 'status',
              render: (_, record) => {
                switch (record['status']) {
                  case 'pending':
                    return <Tag>待處理</Tag>;
                  case 'processing':
                    return <Tag>處理中</Tag>;
                  case 'success':
                    return <Tag>審核通過</Tag>;
                  case 'cancel':
                    return <Tag>審核退件</Tag>;
                  case 'posted':
                    return <Tag>已刊登</Tag>;
                  case 'revoke':
                    return <Tag>已下架</Tag>;
                  case 'changed':
                    return <Tag>已變更</Tag>;
                  default:
                    return '';
                }
              },
            },
            {
              title: '更新時間',
              key: 'updated',
              dataIndex: 'updated',
              render: (_, record) => (
                <span>{new Date(record.updated).toLocaleString('sv')}</span>
              ),
            },
            {
              title: '',
              key: 'action',
              dataIndex: 'action',
              render: (_, record) => (
                <Button
                  onClick={() =>
                    AppActions.navigate(`/${type}-request?id=${record.id}`)
                  }>
                  查看詳情
                </Button>
              ),
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  justify-content: center;

  & > .container {
    max-width: 1000px;
    width: 100%;
    margin: 30px auto;

    & > .title-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export default withPageEntry(RequestList);
